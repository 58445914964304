$(document).on "ready", ->
    Neb.init()

Neb =

    sharePopup: (href) ->
      w = 600
      h = 300
      left = (screen.width / 2) - (w / 2)
      top = (screen.height / 2) - (h / 2)
      shareWindow = window.open(
          href
          "Flagship"
          "location=1,status=1,scrollbars=1,
          width=" + w + ",height=" + h + ",top=" + top + ",left=" + left
      )
      return false

    styleMaps: ->
        map = smartMap.map["smartmap-mapcanvas-1"]
        mapStyles = [
          {
            "featureType": "poi"
            "elementType": "all"
            "stylers": [ { "visibility": "off" } ]
          }
          {
            "featureType": "transit"
            "elementType": "all"
            "stylers": [ { "visibility": "on" } ]
          }
          {
            "featureType": "all"
            "elementType": "all"
            "stylers": [
              { "saturation": -100 }
              { "lightness": 60 }
            ]
          }
          {
            "featureType": "administrative.locality"
            "elementType": "all"
            "stylers": [ { "visibility": "simplified" } ]
          }
          {
            "featureType": "administrative.province"
            "elementType": "geometry.stroke"
            "stylers": [
              { "visibility": "on" }
              { "invert_lightness": true }
              { "color": "#CCCCCC" }
              { "weight": 1.96 }
              { "saturation": 0 }
            ]
          }
          {
            "featureType": "road.arterial"
            "elementType": "labels"
            "stylers": [ { "visibility": "on" } ]
          }
          {
            "featureType": "road.local"
            "elementType": "geometry.stroke"
            "stylers": [ { "visibility": "off" } ]
          }
        ]

        map.setOptions styles: mapStyles

        google.maps.event.addDomListener window, "resize", ->
            Neb.resizeMap()

    resizeMap: ->
        map = smartMap.map["smartmap-mapcanvas-1"]

        # center = map.getCenter()
        google.maps.event.trigger map, "resize"
        # map.setCenter center

        southWest = new (google.maps.LatLng)(40.289443, -103.271484)
        northEast = new (google.maps.LatLng)(42.917681, -95.998535)
        bounds = new (google.maps.LatLngBounds)(southWest, northEast)
        map.fitBounds bounds

    onLoad: ->
        Neb.onResize()

        if $(".smartmap-mapcanvas").length
            Neb.styleMaps()
            Neb.resizeMap()

    onResize: ->

    init: ->
        Neb.onLoad()
        $(window).resize ->
            Neb.onResize()

        # Gallery Modals
        $(".js-modal-gallery").magnificPopup
            type: "image"
            gallery: enabled: true

        # Facebook Share Popups
        $(".js-share-popup").click (event) ->
            event.preventDefault()
            Neb.sharePopup $(this).attr "href"

        # Generic parent closing
        $(".js-close-parent").click (event) ->
            event.preventDefault()
            $(this).parent().addClass "is-hidden"

        # Toggle the map
        $(".js-toggle-map").click (event) ->
            $mapCanvas = $(".smartmap-mapcanvas")
            event.preventDefault()
            $("body").toggleClass "fullscreen"
            $("html, body").animate { scrollTop: 0 }, "fast"
            $mapCanvas.addClass "is-hidden"

            setTimeout (->
                Neb.resizeMap()
                $mapCanvas.removeClass "is-hidden"
            ), 500

class Neb.Controller
